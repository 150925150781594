@font-face {
    font-family: "ItauText-Bold";
    src: url("./shared/fonts/ItauText/ItauText_Bd.eot"),
        url("./shared/fonts/ItauText/ItauText_Bd.eot?#iefix") format("embedded-opentype"),
        url("./shared/fonts/ItauText/ItauText_Bd.woff2") format("woff2"),
        url("./shared/fonts/ItauText/ItauText_Bd.woff") format("woff"),
        url("./shared/fonts/ItauText/ItauText_Bd.ttf") format("truetype");
}

@font-face {
    font-family: "ItauText-Regular";
    src: url("./shared/fonts/ItauText/ItauText_Rg.eot"),
        url("./shared/fonts/ItauText/ItauText_Rg.eot?#iefix") format("embedded-opentype"),
        url("./shared/fonts/ItauText/ItauText_Rg.woff2") format("woff2"),
        url("./shared/fonts/ItauText/ItauText_Rg.woff") format("woff"),
        url("./shared/fonts/ItauText/ItauText_Rg.ttf") format("truetype");
}

html {
    position: relative;
    min-height: 100%;
}

html,
body {
    margin: 0;
    padding: 0;
}

body > framework-analytics-web {
    display: none;
}